<template>
  <div>
    <div style="text-align: right; z-index: 1000;">
      <el-button v-if="formData.State == 0 && (this.$store.state.Login.Department == 'Test' || this.$store.state.Login.Jurisdiction == 0)" @click="qianhe = true"  size="small" style="text-align: right;"  type="primary">{{ $t('All.接单') }}</el-button>
      <!--驳回实验室再次送呈-->
      <el-button v-if="((formData.State == 2 || formData.State == 6) && ((this.$store.state.Login.Department == 'Test' && this.$store.state.Login.User == formData.Receiver) || this.$store.state.Login.Jurisdiction == 0) || this.$store.state.Login.Jurisdiction == 0)" @click="qianhe = true" size="small" style="text-align: right;"  type="primary">{{this.$store.state.Login.Jurisdiction == 0?$t('All.实验室'):''}}{{ $t('All.送呈') }}</el-button>
       <!--驳回实验室再次送呈-->
      <el-button v-if="formData.State == 1 && (this.$store.state.Login.User == formData.Applicant || this.$store.state.Login.Jurisdiction == 0)" @click="Laboratryup" size="small" style="text-align: right;"  type="primary">{{this.$store.state.Login.Jurisdiction == 0?$t('All.送测单人员'):''}}{{ $t('All.送呈') }}</el-button>
      <!--班长核可-->
      <el-button v-if="formData.State == 3 && ((this.$store.state.Login.Department == 'Test' && this.$store.state.Login.Jurisdiction == 4) || this.$store.state.Login.Jurisdiction == 0)" @click="qianhe = true" size="small" style="text-align: right;"  type="primary">{{this.$store.state.Login.Jurisdiction == 0 ? $t('All.班长'):''}}{{ $t('All.签核') }}</el-button>
      <!--主管核可-->
      <el-button v-if="formData.State == 4 && ((this.$store.state.Login.Department == 'Test' && this.$store.state.Login.Jurisdiction == 3) || this.$store.state.Login.Jurisdiction == 0)" @click="qianhe = true" size="small" style="text-align: right;"  type="primary">{{this.$store.state.Login.Jurisdiction == 0 ? $t('All.主管'):''}}{{ $t('All.签核') }}</el-button>
      <el-button v-if="(formData.State == 1 && this.$store.state.Login.User == formData.Applicant) || this.$store.state.Login.Jurisdiction == 0" size="small" style="text-align: right;" @click="Laboratrydel(formData)" type="danger">{{ $t('All.删除') }}</el-button>
      <!--<el-button size="small" style="text-align: right;" v-if="formData.State > 2 && formData.External != 2"  type="primary" v-print="printObj2">打印报告</el-button>-->
      <el-button size="small" style="text-align: right;" v-if="formData.State > 2 && formData.External == 2"  type="primary" v-print="printObj3">{{ $t('All.打印报告') }}</el-button>
      <el-button size="small" style="text-align: right;" v-if="formData.State > 2 && formData.External == 2"  type="primary" @click="printrxf(formData.Numbers)">{{ $t('All.单独报告') }}</el-button>
      <el-button size="small" style="text-align: right;" v-if="formData.State > 2 && formData.External == 0"  type="primary" @click="print(formData.Numbers)">{{ $t('All.打印报告') }}</el-button>
      <el-button size="small" style="text-align: right;"  type="primary" v-print="printObj1">{{ $t('All.打印送测单') }}</el-button>
    </div>
    <div :id="songid" style="width:794px;margin: auto;">
      <img src="../../assets/Img/NIENYI-2.png" :alt="$t('All.年益集团')" style="width: 100px;position: absolute;margin-top: 0px;margin-left: 150px;z-index: -1;">
        <h2 style="text-align: center;">{{this.$t('system.company')}}</h2>
        <h3 style="text-align: center;line-height: 1;">{{ $t('All.实验送测单') }}</h3>
        <h4 style="text-align: center;line-height: 1;">{{ $t('All.通知单号') }}:{{formData.Numbers}}</h4>
        <h4 style="text-align: center;line-height: 1;" class="zhuangtai">{{ $t('All.当前状态') }}:{{ $t(`system.${formData.Statename}`) }}</h4>
    <table border="1" style="width: 98%; margin: 0 auto;" >
        <tr>
            <td class="tdd" style="width: 25%;text-align: center;">
              {{ $t('All.申请人') }}
            </td>
            <td class="tdd" style="width: 25%;text-align: center;">
              {{ $t('system.部门') }}
            </td>
            <td class="tdd" style="width: 25%;text-align: center;">
              {{ $t('All.通知日期') }}
            </td>
            <td class="tdd" style="width: 25%;text-align: center;">
              {{ $t('All.完成日期') }}
            </td>
        </tr>
        <tr>
            <td class="tdd" style="width: 25%;text-align: center;">
              {{formData.Applicantname}}
            </td>
            <td class="tdd" style="width: 25%;text-align: center;">
                <el-select v-show="!isdata" v-model="formData.Department" allow-create filterable :placeholder="$t('All.请选择部门')" :style="{width: '100%'}">
                  <el-option
                    v-for="item in $PublicJs.testdepartment"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              <span v-show="isdata">{{$PublicJs.JurisdictionDepartment(formData.Department)}}</span>
            </td>
            <td class="tdd procedure" style="width: 25%;text-align: center;">
              {{formData.Date}}
            </td>
            <td class="tdd procedure" style="width: 25%;text-align: center;">
              <el-date-picker style="text-align: center;" v-if="(formData.State == 0 && this.$store.state.Login.Department == 'Test' && this.$store.state.Login.Jurisdiction <= 6)" type="datetime" v-model="formData.Estimate"
                :style="{width: '100%'}" :placeholder="$t('All.请选择完成日期')" clearable value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
              {{formData.State == 0 || formData.State == 1? '':formData.Estimate}}
            </td>
        </tr>
        <tr>
            <td class="tdd" style="width: 25%;text-align: center;">
              {{ $t('All.客户') }}/{{ $t('All.厂商') }}
            </td>
            <td class="tdd" style="width: 25%;text-align: center;">
              {{ $t('All.品名') }}/{{ $t('All.料号') }}
            </td>
            <td class="tdd" style="width: 25%;text-align: center;">
              {{ $t('system.实验名称') }}
            </td>
            <td class="tdd" style="width: 25%;text-align: center;">
              {{ $t('All.样品数量') }}
            </td>
        </tr>
        <tr>
            <td class="tdd zhadminadd" style="width: 25%;text-align: center;">
              <el-input v-show="!isdata"  v-model="formData.Customer_manufacturer" :placeholder="$t('All.请输入') + '/' + $t('All.客户')+ '/' + $t('All.厂商')" clearable
                :style="{width: '100%'}"></el-input>
              <span v-show="isdata">{{formData.Customer_manufacturer}}</span>
            </td>
            <td class="tdd zhadminadd" style="width: 25%;text-align: center;">
              <el-input v-show="!isdata"  v-model="formData.Product_item" :placeholder="$t('All.请输入')+ '/' + $t('All.品名')+ '/' + $t('All.料号')" clearable :style="{width: '100%'}">
                </el-input>
              <span v-show="isdata">{{formData.Product_item}}</span>
            </td>
            <td class="tdd zhadminadd" style="width: 25%;text-align: center;">
              <el-input v-show="!isdata"  v-model="formData.Experiment_name" :placeholder="$t('All.请输入实验名称')" clearable :style="{width: '100%'}">
              </el-input>
              <span v-show="isdata">{{formData.Experiment_name}}</span>
            </td>
            <td class="tdd zhadminadd" style="width: 25%;text-align: center;">
              <el-input v-show="!isdata"  v-model="formData.Quantity" :placeholder="$t('All.请输入样品数量')" clearable :style="{width: '100%'}">
              </el-input>
              <span v-show="isdata">{{formData.Quantity}}</span>
            </td>
        </tr>
        <tr>
            <td class="tdd" style="width: 25%;text-align: center;">
              {{ $t('system.紧急状况') }}
            </td>
            <td class="tdd" style="width: 25%;text-align: center;">
              <el-radio-group  v-show="!isdata" v-model="formData.Urgent_state" size="medium">
                <el-radio style="margin-right: 10px;" v-for="(item, index) in Urgent_stateOptions" :key="index" :label="item.value">{{item.label}}</el-radio>
              </el-radio-group>
              <span v-show="isdata">{{ $t(`All.${formData.Urgent_state}`) }}</span>
            </td>
            <td class="tdd" style="width: 25%;text-align: center;">
              {{ $t('All.含卤情况') }}
            </td>
            <td class="tdd" style="width: 25%;text-align: center;">
              <el-radio-group v-show="!isdata" v-model="formData.Halogen" size="medium">
                <el-radio v-for="(item, index) in HalogenOptions" :key="index" :label="item.value">{{item.label}}</el-radio>
              </el-radio-group>
              <span v-show="isdata">{{formData.Halogen}}</span>
            </td>
        </tr>
        <tr>
            <td class="tdd" style="width: 25%;text-align: center;">
              {{ $t('All.工单号') }}
            </td>
            <td class="tdd" style="width: 25%;text-align: center;">
              <el-input v-show="!isdata"  v-model="formData.Gongdan" :placeholder="$t('All.请输入工单号')" clearable :style="{width: '100%'}"></el-input>
              <span v-show="isdata">{{formData.Gongdan}}</span>
            </td>
            <td class="tdd" style="width: 25%;text-align: center;">
            </td>
            <td class="tdd" style="width: 25%;text-align: center;">
            </td>
        </tr>
        <tr>
            <td class="tdd" style="width: 25%;text-align: center;">
             {{ $t('All.实验要求') }} / {{ $t('All.条件') }}
            </td>
            <td class="tdd zhadminadd" style="width: 75%;" colspan="3">
              <el-input class="session-file__form" :disabled="isdata" v-model="formData.Requirements" type="textarea" :placeholder="this.$t('All.实验要求') + '/' + this.$t('All.条件') + this.$t('All.不得超过6行')"
                  :autosize="{minRows: 6, maxRows: 6}" :style="{width: '100%'}"></el-input>
            </td>
        </tr>
        <tr valign="top">
            <td class="tdd" style="width: 25%; height: 360px; text-align: center;">
              {{ $t('All.参考文件') }}
            </td>
            <td class="tdd" :class="!isdata && formData.State == 1?'':'uploaddise'" style="width: 75%;" colspan="3">
              <el-upload :disabled="isdata" list-type="picture-card" :action="`${$Upload}/api/Laboratory/File_user_upload/`" :data="filedata" :on-success="handsuccess" :file-list="fileList">
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail" :src="xsimg(file)" :alt="file.name">
                    <span class="el-upload-list__item-actions" :alt="file.name">
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                            <i class="el-icon-zoom-in"></i>
                        </span>
                        <span class="el-upload-list__item-delete" @click="handleDownload(file)">
                            <i class="el-icon-download"></i>
                        </span>
                        <span v-show="!isdata && formData.State == 1" class="el-upload-list__item-delete" @click="handleRemove(file)">
                            <i class="el-icon-delete"></i>
                        </span>
                    </span>
                    <p>{{file.name}}</p>
                </div>
                <el-dialog :visible.sync="dialogVisible" append-to-body>
                    <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
            </el-upload>
            </td>
        </tr>
        <tr>
            <td class="tdd" style="width: 25%;text-align: center;">
               {{ $t('All.备注') }}
            </td>
            <td class="tdd zhadminadd" style="width: 75%;" colspan="3">
              <el-input class="session-file__form" :disabled="isdata" v-model="formData.Remarks" type="textarea"
                  :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
            </td>
        </tr>
      </table>
      <table style="width: 90%;border-top: 0; margin: 0 auto;" class="table-layout">
          <tr>
            <td class="tdd"  style="text-align: right;" colspan="6">
                NYS1008-02-C
            </td>
          </tr>
      </table>
    </div>
      <el-form ref="elForm" v-if="formData.State >= 2 || this.$store.state.Login.Jurisdiction == 0" :model="formData" :rules="rules" style="max-width: 794px;margin: auto;" size="medium" label-width="90px"
      label-position="top">
      <el-form-item :label="$t('All.内测')+'/'+ $t('All.外测')" v-if="formData.State >= 2" prop="External">
          <el-radio-group style="padding-left: 5%;"  v-if="((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver )"  v-model="formData.External" size="medium">
            <el-radio v-for="(item, index) in ExternalOptions" :key="index" :label="item.value">{{item.label}}</el-radio>
          </el-radio-group>
          <span style="padding-left: 5%;" v-if="!((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)">{{formData.External == 0?$t('All.内测'):formData.External == 2? $t('All.内测')+ '(XRF)':$t('All.外测')}}</span>
      </el-form-item>
      <el-form-item v-if="formData.State >= 2" :class="((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)?'':'uploaddise'" :label="$t('All.实验报告')">
        <Flowtable v-if="formData.External == 0" :id="baoid"  :Numbers="formData.Numbers" :FormData="formData"></Flowtable>
        <Flowxrftable :PrintObj3="PrintObj3" :id="baoid" v-if="formData.External == 2" :Numbers="formData.Numbers" :FormData="formData"></Flowxrftable>
        <el-upload
        style="padding-left: 5%;"
        v-if="formData.External == 1" :disabled="!((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)"
        list-type="picture-card" :action="`${$Upload}/api/Laboratory/File_user_upload/`"
        :data="filedata3"
        :file-list="fileList3"
        :http-request="customRequest2">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail" :src="xsimg(file)" :alt="file.name">
                <span class="el-upload-list__item-actions" :alt="file.name">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete" @click="handleDownload(file)">
                        <i class="el-icon-download"></i>
                    </span>
                    <span v-show="(formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver" class="el-upload-list__item-delete" @click="handleRemove3(file)">
                        <i class="el-icon-delete"></i>
                    </span>
                </span>
                <p>{{file.name}}</p>
            </div>
          </el-upload>
      </el-form-item>
      <el-form-item v-if="formData.State >= 2" :class="((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)?'':'uploaddise'" :label="$t('All.实验报告附件')">
        <el-upload
        style="padding-left: 5%;"
        v-if="formData.External == 0" :disabled="!((formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver)"
        list-type="picture-card"
        :action="`${$Upload}/api/Laboratory/File_user_upload_task/`"
        :file-list="fileList4"
        :http-request="customRequest">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail" :src="xsimg(file)" :alt="file.name">
                <span class="el-upload-list__item-actions" :alt="file.name">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete" @click="handleDownload(file)">
                        <i class="el-icon-download"></i>
                    </span>
                    <span v-show="(formData.State == 2 || formData.State == 6) && $store.state.Login.User == formData.Receiver" class="el-upload-list__item-delete" @click="handleRemove4(file)">
                        <i class="el-icon-delete"></i>
                    </span>
                </span>
                <p>{{file.name}}</p>
            </div>
          </el-upload>
      </el-form-item>
    </el-form>
    <el-form  :model="formData" :rules="rules" style="max-width: 800px;margin: auto;" size="medium" label-width="90px"
      label-position="top">
    <el-form-item :label="$t('All.签核记录')">
        <el-radio-group  style="padding-bottom: 20px;padding-left: 5%;" v-model="reverse">
          <el-radio :label="true">{{ $t('All.倒序') }}</el-radio>
          <el-radio :label="false">{{ $t('All.正序') }}</el-radio>
        </el-radio-group>
        <el-timeline :reverse="reverse">
          <el-timeline-item
            v-for="(activity, index) in Signlog"
            :key="index"
            :timestamp="activity.Date">
            {{activity.Action}}
            <p>{{activity.Opinion}}</p>
          </el-timeline-item>
        </el-timeline>
      </el-form-item>
    </el-form>
    <Qianhe v-if="qianhe" :Qianhecolse="Qianhecolse" :QianForm="QianForm"></Qianhe>
  </div>
</template>
<script>
import Qianhe from './Qianhe.vue'
import { mapMutations } from 'vuex'
import Flowtable from './Upload/Flowtable.vue'
import Flowxrftable from './Upload/Flowxrftable.vue'
export default {
  components: {
    Qianhe,
    Flowtable,
    Flowxrftable
  },
  data () {
    return {
      formData: {
        Date: '',
        Numbers: '',
        Product_item: '',
        Customer_manufacturer: '',
        Experiment_name: '',
        Quantity: '',
        Urgent_state: '',
        Requirements: '',
        Remarks: '',
        Applicant: this.$store.state.Login.User,
        Applicantname: this.$store.state.Login.Username,
        Factory: this.$store.state.Login.Factory,
        Receiver: '',
        Receivername: '',
        Reviewer: '',
        Reviewername: '',
        Approver: '',
        Approvername: '',
        Filename: '',
        Fileurl: '',
        State: 0,
        Estimate: '',
        Theredays: 0,
        Statename: '',
        Nextname: this.$t('All.等待实验室接收')
      },
      rules: {
        Date: [{
          required: true,
          message: this.$t('All.请输入开单日期'),
          trigger: 'blur'
        }],
        Estimate: [{
          required: true,
          message: this.$t('All.请选择完成日期'),
          trigger: 'change'
        }],
        Numbers: [{
          required: true,
          message: this.$t('All.请输入通知单号'),
          trigger: 'blur'
        }],
        Product_item: [{
          required: true,
          message: this.$t('All.请输入品名料号'),
          trigger: 'blur'
        }],
        Customer_manufacturer: [{
          required: true,
          message: this.$t('All.请输入客户厂商'),
          trigger: 'blur'
        }],
        Experiment_name: [{
          required: true,
          message: this.$t('All.请输入实验名称'),
          trigger: 'blur'
        }],
        Quantity: [{
          required: true,
          message: this.$t('All.请输入样品数量且只能输入数字'),
          trigger: 'blur'
        }, {
          pattern: /^\d+(\.\d+)?$/,
          message: this.$t('All.只能输入数字'),
          trigger: 'blur'
        }],
        Urgent_state: [{
          required: true,
          message: this.$t('All.紧急状况不能为空'),
          trigger: 'change'
        }],
        Halogen: [{
          required: true,
          message: this.$t('All.含卤情况不能为空'),
          trigger: 'change'
        }],
        Requirements: [{
          required: true,
          message: this.$t('All.请输入实验要求条件'),
          trigger: 'blur'
        }]
      },
      Urgent_stateOptions: [{
        label: this.$t('All.特急'),
        value: this.$t('All.特急')
      }, {
        label: this.$t('All.急'),
        value: this.$t('All.急')
      }, {
        label: this.$t('All.普通'),
        value: this.$t('All.普通')
      }],
      HalogenOptions: [{
        label: 'HS',
        value: 'HS'
      }, {
        label: 'HSF',
        value: 'HSF'
      }],
      ExternalOptions: [{
        label: this.$t('All.内测'),
        value: 0
      }, {
        label: this.$t('All.外测'),
        value: 1
      }],
      fileList: [],
      filedata: {
        Numbers: '',
        Type: 0,
        User: this.$store.state.Login.User,
        Username: this.$store.state.Login.Username,
        Factory: this.$store.state.Login.Factory
      },
      fileListshiyan: [],
      filedatashiyan: {
        Numbers: '',
        Type: 1
      },
      dialogVisible: false,
      dialogImageUrl: '',
      isdata: true,
      qianhe: false,
      Signlog: [],
      reverse: true,
      tableData: [],
      fileList3: [],
      filedata3: {
        Numbers: '',
        Type: 2,
        User: this.$store.state.Login.User,
        Username: this.$store.state.Login.Username,
        Factory: this.$store.state.Login.Factory
      },
      fileList4: [],
      filedata4: {
        Numbers: '',
        Type: 3,
        User: this.$store.state.Login.User,
        Username: this.$store.state.Login.Username,
        Factory: this.$store.state.Login.Factory
      },
      printisbool1: true,
      printObj1: {
        id: 'song' + this.$store.state.Testdata.Numbers, // 这里是要打印元素的ID
        popTitle: this.$t('All.年益实业股份有限公司'), // 打印的标题
        extraCss: '', // 打印可引入外部的一个 css 文件
        extraHead: '' // 打印头部文字
      },
      printObj2: {
        id: 'printTest', // 这里是要打印元素的ID
        popTitle: this.$t('All.年益实业股份有限公司'), // 打印的标题
        extraCss: '', // 打印可引入外部的一个 css 文件
        extraHead: '' // 打印头部文字
      },
      printObj3: {
        id: 'bao' + this.$store.state.Testdata.Numbers, // 这里是要打印元素的ID
        popTitle: this.$t('All.年益实业股份有限公司'), // 打印的标题
        extraCss: '', // 打印可引入外部的一个 css 文件
        extraHead: '' // 打印头部文字
      },
      songid: '',
      baoid: '',
      percentage: 0
    }
  },
  computed: {
  },
  watch: {},
  props: ['Addtab', 'RemoveTab', 'Renewtargename'],
  created () {
    if (this.$store.state.Testdata === null) {
      this.TabComponentFun('TestExamine')
      this.$message.error(this.$t('All.订单数据不存在'))
    } else {
      this.formData = this.$store.state.Testdata
      if (this.formData.State === 1) {
        this.isdata = false
      }
      if (this.formData.State === 0) {
        this.formData.Estimate = new Date()
      }
      this.filedata.Numbers = this.formData.Numbers
      this.filedata3.Numbers = this.formData.Numbers
      this.filedata4.Numbers = this.formData.Numbers
      this.filedatashiyan.Numbers = this.formData.Numbers
      this.LaUserGetAllFiles(this.formData.Numbers)
      this.Signloglist(this.formData.Numbers)
    }
    this.songid = 'song' + this.$store.state.Testdata.Numbers
    this.baoid = 'bao' + this.$store.state.Testdata.Numbers
  },
  mounted () {
  },
  methods: {
    ...mapMutations(['Testdata', 'TabComponentFun']),
    PrintObj3 (row) {
      this.printObj3.id = row
    },
    getCurrentTime (date) {
      // 获取当前时间并打印
      const yy = date.getFullYear()
      const mm = date.getMonth() + 1
      const dd = date.getDate()
      // 时
      const hour = date.getHours()
      // 分
      const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      // 秒
      const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()

      const gettime = yy + '-' + mm + '-' + dd + ' ' + hour + ':' + minute + ':' + second
      return gettime
    },
    Qianhecolse () {
      this.qianhe = false
    },
    async handsuccess (response) {
      const data = {
        name: '',
        url: ''
      }
      if (response.status !== 200) return this.$message.error(this.$t('All.图片上传失败'))
      data.name = response.name
      data.url = response.url
      this.fileList.splice(0, 0, data)
    },
    handleDownload (file) {
      var url = file.url
      var alink = document.createElement('a')
      fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        alink.href = URL.createObjectURL(blob)
        alink.download = file.name
        document.body.appendChild(alink)
        alink.click()
      })
    },
    async handsuccessshiyan (response) {
      const data = {
        name: '',
        url: ''
      }
      if (response.status !== 200) return this.$message.error(this.$t('All.图片上传失败'))
      data.name = response.name
      data.url = response.url
      this.fileListshiyan.splice(0, 0, data)
    },
    async handsuccess3 (response) {
      const data = {
        name: '',
        url: ''
      }
      if (response.status !== 200) return this.$message.error(this.$t('All.图片上传失败'))
      data.name = response.name
      data.url = response.url
      this.fileList3.splice(0, 0, data)
    },
    async handleRemove (file) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        var row = {
          Name: file.name,
          Type: 0,
          Numbers: this.formData.Numbers
        }
        const { data: res } = await this.$http.post('/api/Laboratory/File_user_del', row)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.fileList.some((item, i) => {
          if (item.name === file.name) {
            this.fileList.splice(i, 1)
          }
        })
        this.$message.success(`${this.$t('All.删除')}${file.name}${this.$t('All.成功')}！`)
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    async handleRemove3 (file) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        var row = {
          Name: file.name,
          Type: 2,
          Numbers: this.formData.Numbers
        }
        const { data: res } = await this.$http.post('/api/Laboratory/File_user_del', row)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.fileList3.some((item, i) => {
          if (item.name === file.name) {
            this.fileList3.splice(i, 1)
          }
        })
        this.$message.success(`${this.$t('All.删除')}${file.name}${this.$t('All.成功')}！`)
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    async handleRemove4 (file) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        var row = {
          Name: file.name,
          Type: 3,
          Numbers: this.formData.Numbers
        }
        const { data: res } = await this.$http.post('/api/Laboratory/File_user_del', row)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.fileList4.some((item, i) => {
          if (item.name === file.name) {
            this.fileList4.splice(i, 1)
          }
        })
        this.$message.success(`${this.$t('All.删除')}${file.name}${this.$t('All.成功')}！`)
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    async handleRemoveshiyan (file) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        var row = {
          Name: file.name,
          Type: 1,
          Numbers: this.formData.Numbers
        }
        const { data: res } = await this.$http.post('/api/Laboratory/File_user_del', row)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.fileListshiyan.some((item, i) => {
          if (item.name === file.name) {
            this.fileListshiyan.splice(i, 1)
          }
        })
        this.$message.success(`${this.$t('All.删除')}${file.name}${this.$t('All.成功')}！`)
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    async LaUserGetAllFiles (row) {
      try {
        const { data: res } = await this.$http.get('/api/Laboratory/LaUserGetAllFiles?Number=' + row + '&Factory=' + this.$store.state.Login.Factory)
        if (res.status !== 200) return
        for (let index = 0; index < res.response.length; index++) {
          if (res.response[index].Type === 1) {
            this.fileListshiyan.splice(0, 0, res.response[index])
          } else if (res.response[index].Type === 0) {
            this.fileList.splice(0, 0, res.response[index])
          } else if (res.response[index].Type === 2) {
            this.fileList3.splice(0, 0, res.response[index])
          } else if (res.response[index].Type === 3) {
            this.fileList4.splice(0, 0, res.response[index])
          }
        }
      } catch (error) {
        alert(error)
      }
    },
    async Signloglist (row) {
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/Signloglist?Numbers=' + row)
        if (res.status !== 200) return
        this.Signlog = res.response
      } catch (error) {
        alert(error)
      }
    },
    handlePictureCardPreview (file) {
      if (this.$store.state.Login.Factory === 'All' || this.$store.state.Login.Factory === undefined) {
        window.open(this.$Upload + file.url)
      } else {
        window.open(file.url)
      }
    },
    xsimg (val) {
      var index = val.name.lastIndexOf('.')
      const obj = val.name.substring(index + 1, val.name.length)
      const objj = obj.toUpperCase()
      switch (objj) {
        case 'JPEG':
          return val.url
        case 'BMP':
          return val.url
        case 'TIFF':
          return val.url
        case 'GIF':
          return val.url
        case 'PNG':
          return val.url
        case 'JPG':
          return val.url
        case 'PDF':
          return require('@/assets/Img/pdf.jpg')
        case 'XLS':
          return require('@/assets/Img/excle.jpg')
        case 'XLSX':
          return require('@/assets/Img/excle.jpg')
        case 'RAR':
          return require('@/assets/Img/rar.jpeg')
        case 'ZIP':
          return require('@/assets/Img/rar.jpeg')
        default:
          return require('@/assets/Img/nullimg.jpeg')
      }
    },
    submitForm () {
      this.$refs.elForm.validate(async valid => {
        if (!valid) return this.$message.error(this.$t('All.请完善表单'))
        // TODO 提交表单
        const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
        try {
          const { data: res } = await this.$http.post('/api/Laboratory/Laboratryadd', this.formData)
          if (res.status !== 200) {
            loadingInstance.close()
            return this.$message.error(res.response)
          }
          loadingInstance.close()
          this.formData.Numbers = res.response
          this.formData.Date = this.getCurrentTime()
        } catch (error) {
          loadingInstance.close()
          alert(this.$t('All.网络异常'))
        }
      })
    },
    resetForm () {
      this.$refs.elForm.resetFields()
    },
    async QianForm (row) {
      row.State = this.formData.State
      const data = {
        Laboratorydata: this.formData,
        Signstae: row
      }
      // TODO 提交表单
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/LaboratrySign', data)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        loadingInstance.close()
        this.Testdata = []
        this.Renewtargename(this.$t('All.实验室') + this.$t('All.待审区'), 'TestExamine', 'Lagetflow')
        this.RemoveTab(this.formData.Numbers)
      } catch (error) {
        loadingInstance.close()
        console.log(error)
        alert(this.$t('All.网络异常'))
      }
    },
    async Laboratryup () {
      this.formData.State = 0
      // TODO 提交表单
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/Laboratryup', this.formData)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        loadingInstance.close()
        this.Testdata = []
        this.Renewtargename(this.$t('All.实验室') + this.$t('All.待审区'), 'TestExamine', 'Lagetflow')
        this.RemoveTab(this.formData.Numbers)
      } catch (error) {
        loadingInstance.close()
        alert(this.$t('All.网络异常'))
      }
    },
    async Laboratrydel (row) {
      const { data: res } = await this.$http.post('/api/Laboratory/Laboratrydel', row)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.Renewtargename(this.$t('All.实验室') + this.$t('All.待审区'), 'TestExamine', 'Lagetflow')
      this.RemoveTab(this.formData.Numbers)
    },
    formatSex (value) {
      if (value === '1') {
        return this.$t('All.男')
      }
      if (value === '0') {
        return this.$t('All.女')
      }
      return ''
    },
    formatMultiSex (values) {
      if (values) {
        return values.map(val => this.formatSex(val)).join(',')
      }
      return ''
    },
    print (row) {
      this.$message.success(this.$t('All.如打不开') + ',' + this.$t('All.请关闭弹窗拦截'))
      window.open(`${this.$weburl}/#/Flowtable/${row}`, '_blank')
    },
    printrxf (row) {
      this.$message.success(this.$t('All.如打不开') + ',' + this.$t('All.请关闭弹窗拦截'))
      window.open(`${this.$weburl}/#/Flowxrftable/${row}`, '_blank')
    },
    // 自定义上传方法，用于处理分片上传
    customRequest ({ file }) {
      const loadingInstance = this.$loading({ text: `上传文件中，已上传${this.percentage}%`, spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const chunkSize = 1024 * 1024 * 1 // 设置每个分片的大小，这里为1MB
      let index = 0
      let start = 0 // 初始化分片开始的索引
      // 定义上传分片的函数
      const uploadChunk = async () => {
        // 获取当前分片的数据
        const chunk = file.slice(start, start + chunkSize)
        const formData = new FormData() // 创建FormData对象用于发送数据
        formData.append('Numbers', this.filedata4.Numbers) // 添加分片数据
        formData.append('Type', this.filedata4.Type) // 添加分片数据
        formData.append('User', this.filedata4.User) // 添加分片数据
        formData.append('Username', this.filedata4.Username) // 添加分片数据
        formData.append('Factory', this.filedata4.Factory) // 添加分片数据
        formData.append('Filename', file.name) // 添加分片数据
        formData.append('Index', index) // 添加分片数据
        formData.append('Chunk', start) // 添加当前分片的索引
        formData.append('Chunks', Math.ceil(file.size / chunkSize)) // 添加总分片数
        formData.append('file', chunk) // 添加分片数据
        // 使用axios发送POST请求上传分片
        const { data: res } = await this.$http.post(`${this.$Upload}/api/Laboratory/File_user_upload_task/`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        if (res.status !== 200) {
          this.percentage = 0
          loadingInstance.close()
          return this.$message.error('上传失败，请重新上传')
        }
        // 如果还有剩余的分片，继续上传
        if (start + chunkSize < file.size) {
          start += chunkSize
          index++
          this.percentage = Math.ceil(index / Math.ceil(file.size / chunkSize) * 100)
          // 通过重新设置loadingInstance的text属性来更新加载提示文本
          loadingInstance.text = `上传文件中，已上传${this.percentage}%`
          uploadChunk()
        } else {
          // 所有分片上传完成，调用onSuccess回调
          const data = {
            name: res.name,
            url: res.url
          }
          this.fileList4.splice(0, 0, data)
          loadingInstance.close()
          this.percentage = 0
          this.$message.success(res.msg)
        }
      }

      // 开始上传第一个分片
      uploadChunk()
    },
    // 自定义上传方法，用于处理分片上传
    customRequest2 ({ file }) {
      const loadingInstance = this.$loading({ text: `上传文件中，已上传${this.percentage}%`, spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const chunkSize = 1024 * 1024 * 1 // 设置每个分片的大小，这里为1MB
      let index = 0
      let start = 0 // 初始化分片开始的索引
      // 定义上传分片的函数
      const uploadChunk = async () => {
        // 获取当前分片的数据
        const chunk = file.slice(start, start + chunkSize)
        const formData = new FormData() // 创建FormData对象用于发送数据
        formData.append('Numbers', this.filedata3.Numbers) // 添加分片数据
        formData.append('Type', this.filedata3.Type) // 添加分片数据
        formData.append('User', this.filedata3.User) // 添加分片数据
        formData.append('Username', this.filedata3.Username) // 添加分片数据
        formData.append('Factory', this.filedata3.Factory) // 添加分片数据
        formData.append('Filename', file.name) // 添加分片数据
        formData.append('Index', index) // 添加分片数据
        formData.append('Chunk', start) // 添加当前分片的索引
        formData.append('Chunks', Math.ceil(file.size / chunkSize)) // 添加总分片数
        formData.append('file', chunk) // 添加分片数据
        // 使用axios发送POST请求上传分片
        const { data: res } = await this.$http.post(`${this.$Upload}/api/Laboratory/File_user_upload_task/`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        if (res.status !== 200) {
          this.percentage = 0
          loadingInstance.close()
          return this.$message.error('上传失败，请重新上传')
        }
        // 如果还有剩余的分片，继续上传
        if (start + chunkSize < file.size) {
          start += chunkSize
          index++
          this.percentage = Math.ceil(index / Math.ceil(file.size / chunkSize) * 100)
          // 通过重新设置loadingInstance的text属性来更新加载提示文本
          loadingInstance.text = `上传文件中，已上传${this.percentage}%`
          uploadChunk()
        } else {
          // 所有分片上传完成，调用onSuccess回调
          const data = {
            name: res.name,
            url: this.$Upload + res.url
          }
          this.fileList3.splice(0, 0, data)
          loadingInstance.close()
          this.percentage = 0
          this.$message.success(res.msg)
        }
      }

      // 开始上传第一个分片
      uploadChunk()
    }
  }
}
</script>
<style lang="less" scoped>
@page {
    size: auto;
    margin: 3mm;
  }

  html {
    background-color: #ffffff;
    height: auto;
    margin: 0px;
  }

  body {
    border: solid 1px #ffffff;
    margin: 10mm 15mm 10mm 15mm;
  }
</style>
