import { render, staticRenderFns } from "./Testflow.vue?vue&type=template&id=500c222f&scoped=true"
import script from "./Testflow.vue?vue&type=script&lang=js"
export * from "./Testflow.vue?vue&type=script&lang=js"
import style0 from "./Testflow.vue?vue&type=style&index=0&id=500c222f&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "500c222f",
  null
  
)

export default component.exports